<template>
	<div class="history" v-if="!loading">
		<v-row>
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<v-spacer/>
						<v-btn color="" outlined class="mr-2 mb-1" :to="{name: 'OrderHistoryIndex'}" id="history-back">
							Back
						</v-btn>
						<v-btn color="green" class="white--text mr-2 mb-1" large depressed @click="reorder">
							Reorder
						</v-btn>
					</v-card-title>
					<v-card-title class="d-block">
						<div class="text-h4">History Order: #{{ order.name }}</div>
						<div class="text-h6 font-weight-400 my-1">
							Status:
							<v-chip color="black" outlined label>{{ order.status.name_en }}</v-chip>
						</div>
					</v-card-title>
					<block-products/>
				</v-card>
			</v-col>
			<v-col cols="12" v-if="order.note_client">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<div class="text-h5 font-weight-500">Additional information</div>
					</v-card-title>
					<v-card-title class="d-block" v-html="order.note_client"/>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<div class="text-h5 font-weight-500">Billing details</div>
					</v-card-title>
					<v-card-text class="mt-2 black--text text-body-1">
						<span class="black--text">{{ order.billing_detail.name }}</span><br>
						<span class="black--text">{{ order.billing_detail.street }}</span><br>
						<span class="black--text mr-1">{{ order.billing_detail.postal_code }}</span>
						<span class="black--text">{{ order.billing_detail.city }}</span><br>
						<span class="black--text">{{ order.billing_detail.country.name }}</span>
						<span v-if="order.billing_detail.state" class="black--text">,
							{{ order.billing_detail.state.name }}
						</span>
						<br>
						<span v-if="order.billing_detail.vat_ue" class="black--text">
							VAT UE: {{ order.billing_detail.vat_ue }}
						</span>
						<span v-if="order.billing_detail.vat_uk" class="black--text">
							VAT UK: {{ order.billing_detail.vat_uk }}
						</span>
						<span v-if="order.billing_detail.company_data" class="black--text">
							Company data: {{ order.billing_detail.company_data }}
						</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<div class="text-h5 font-weight-500">Delivery address</div>
					</v-card-title>
					<v-card-text class="mt-2 black--text text-body-1">
						<span class="">{{ order.address.name }}</span><br>
						<span class="">{{ order.address.street }}</span><br>
						<span class=" mr-1">{{ order.address.postal_code }}</span>
						<span class="">{{ order.address.city }}</span><br>
						<span class="">{{ order.address.country.name }}</span>
						<span v-if="order.address.state" class="">,
							{{ order.address.state.name }}
						</span>
					</v-card-text>
				</v-card>
			</v-col>
<!--			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<div class="text-h5 font-weight-500">Billing details</div>
					</v-card-title>
					<v-card-text class="mt-2 black&#45;&#45;text text-body-1">
						<span class="">{{ order.billing_detail.name }}</span><br>
						<span class="">{{ order.billing_detail.street }}</span><br>
						<span class=" mr-1">{{ order.billing_detail.postal_code }}</span>
						<span class="">{{ order.billing_detail.city }}</span><br>
						<span class="">{{ order.billing_detail.country.name }}</span>
						<span v-if="order.billing_detail.state" class="">,
							{{ order.billing_detail.state.name }}
						</span>
					</v-card-text>
				</v-card>
			</v-col>-->
			<v-col cols="12">
				<v-card class="" outlined>
					<v-card-title class="pb-0">
						<div class="text-h5 font-weight-500">Contact details</div>
					</v-card-title>
					<v-card-text class="mt-2 black--text text-body-1">
						<span class="">{{ order.contact_detail.name }}</span><br>
						<span class="">Email: {{ order.contact_detail.email }}</span><br>
						<span class=" mr-1">Phone: {{ order.contact_detail.phone }}</span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>
</template>
<script>

import BlockProducts from "@/views/OrderHistory/Show/Block/BlockProducts";

export default {
	name: 'OrderHistoryShow',
	components: {BlockProducts},
	computed: {
		order() {
			return this.$store.getters['ORDER_HISTORY_STORE/ORDER'];
		},

	},
	props: ['number'],
	data: () => ({
		loading: false,
	}),
	methods: {
		reorder() {
			this.$swal({
				title: 'Are you sure you want to reorder? ',
				text: 'Your shopping cart will be cleared.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'Cancel',
				customClass: {
					confirmButton: 'v-btn v-btn--contained reorder-yes',
					cancelButton: 'v-btn v-btn--contained reorder-cancel',
				},
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('ORDER_HISTORY_STORE/reorder', this.number)
						.then((resp) => {
							if (resp.data.success) {
								this.$swal({
									icon: 'success',
									title: 'Success',
									text: 'Your order form has been updated and now includes the reordered products',
								});

								this.$router.push({name: 'Dashboard'});
							}
							this.loading = false
						})
						.catch(err => {
							console.error(err)
							this.loading = false
						});

				}
			});
		},
	},
	created() {
		this.loading = true;

		this.$store.dispatch('ORDER_HISTORY_STORE/get', this.number)
			.then(() => {
				this.loading = false
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	}
};
</script>
